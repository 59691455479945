// Translated
// Migrated
<template>
  <div class="slide-carousel-container relative z-[0]">
    <Swiper
      class="h-full"
      v-bind="swiperPropsValues"
      dots-type="light"
      dots-align="bottom"
      arrows-type="circular"
      loop
    >
      <component
        :is="slide.component"
        v-for="(slide, index) in getSlides"
        :key="index"
        class="relative overflow-hidden h-full block"
        :to="slide.link ? slide.link.href : null"
      >
        <ResponsiveImage
          class="carousel-image h-full"
          :image="slide.image"
          :alt="slide?.description || slide?.link?.text || $t('siteTitleBrand')"
          cover
        />
        <div class="slide-carousel absolute size-full z-[3] top-0 flex flex-col justify-center items-center px-12">
          <div
            v-if="slide.title"
            class="slide-carousel-title text-white text-center h-auto font-bold"
            :class="titleClass"
          >
            {{ slide.title }}
          </div>
          <div
            v-if="slide.description"
            class="slide-carousel-description inline text-white text-shadow text-center font-normal whitespace-pre-line"
          >
            {{ slide.description }}
          </div>
          <span
            v-if="slide.link?.text"
            class="slide-carousel-link h4 mt-2 text-white text-shadow font-normal"
          >
            <span :data-i18n="slide.link.text">
              {{ $t(slide.link.text) }}
            </span>
            <fa
              class="ml-4 absolute"
              icon="arrow-right-long"
            />
          </span>
        </div>
      </component>
    </Swiper>
  </div>
</template>

<script setup>
import swiperProps from '@packages/swiper/constants/props.js'

import { NuxtLink } from '#components'

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const { slides, titleClass, dotsType, maxSlides, ...swiperPropsValuesRaw } = defineProps({
  ...swiperProps,
  /**
   * @prop {Array.<{
   *  image: String
   *  title?: String
   *  description?: String2
   *  link?: {
   *    href: String
   *    text?: String
   *  }
   * }>} slides
   */
  slides: {
    type: Array,
    required: true,
    default: () => [],
  },

  titleClass: {
    type: String,
    default: 'text-[3.5rem] font-bold',
  },
})

const swiperPropsValues = ref(swiperPropsValuesRaw)

const getSlides = computed(() => {
  if ((slides || []).length === 0) {
    return [{
      image: null,
    }]
  }
  return slides
    .filter(Boolean)
    .map(slide => ({
      ...slide,
      component: slide.link ? NuxtLink : 'div',
    }))
    .slice(0, maxSlides)
})
</script>

<style lang="scss">
.slide-carousel-container {
  .slide-carousel {
    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.57);

    &-title {
      letter-spacing: 0.5px;
      font-size: 3.125rem;

      @media (max-width: theme('screens.md')) {
        font-size: 2.3rem;
        line-height: 42px;
      }

      @media (max-width: theme('screens.sm')) {
        font-size: 2.1rem;
        line-height: 42px;
      }
    }

    &-description {
      font-size: 1.8rem;
      height: auto !important;
      letter-spacing: .3px;
      line-height: 37px;

      @media (max-width: theme('screens.md')) {
        font-size: 1.8rem;
        line-height: 36px;
      }

      @media (max-width: theme('screens.sm')) {
        font-size: 1.2rem;
        line-height: 26px;
      }
    }

    &-link {
      @media (max-width: theme('screens.sm')) {
        font-size: 1.3rem;
      }
    }
  }
}
</style>
